@import "src/assets/styles/colors";

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary
);

@import "src/../node_modules/bootstrap/scss/bootstrap";

html{
  font-family: Roboto, Helvetica, sans-serif;
}
