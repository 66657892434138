@import "src/assets/styles/colors";

.privacy-policy {
  display: flex;

  .modal-title {
    color: $primary;
  }
}

.privacy-policy__official-address {
  margin-left: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
