.bot-twilio {
  position: relative;
  width: 100%;
}

.Twilio.Twilio-EntryPoint {
  display: none;
}

.Twilio.Twilio-MainContainer {
  top: 4rem;
  max-height: none;
  height: auto;
}

.Twilio.Twilio-MessagingCanvas {
  max-width: none;
}

.Twilio.Twilio-MessageList {
  padding-bottom: 1rem;
}

.Twilio.Twilio-MessageListItem {
  margin-top: 0.7rem;
}

.Twilio.Twilio-MessageInput {
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 30%);
}

.Twilio {
  .Twilio-MessageBubble-UserName {
    display: none;
  }

  .Twilio-MessageBubble-Time {
    font-size: 0.7rem;
  }

  .Twilio-MessageBubble-Body {
    font-size: 0.9rem;
    font-weight: 600;
  }
}
