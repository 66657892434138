@import "src/assets/styles/breakpoints";

.chatbot {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  a {
    color: #ffffff;
  }
}

// Force radii on chat bubbles
.webchat__bubble {
  padding: 0.12em;
  font-size: 0.9rem;

  &:not(.webchat__bubble--from-user) {
    .webchat__bubble__content {
      border-top-left-radius: 4px !important;
    }
  }

  &.webchat__bubble--from-user {
    .webchat__bubble__content {
      border-top-right-radius: 4px !important;
    }
  }
}

.webchat__bubble__content {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 30%);
  padding-right: 0.4em;
  padding-left: 0.4em;
}

.webchat__markdown__external-link-icon {
  display: none;
}

.webchat__bubble--from-user {
  padding: 0.188rem;
}

.webchat__scroll-to-end-button {
  // To overwrite the css and hide the button generated by bot framework
  display: none !important;
}

.webchat__send-box {
  z-index: 1000;
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 30%);
  min-height: 110px;
  background: #f7f7f7;
}

// Ensure text box display is hidden from the get-go
.chatbot .webchat__send-box div.webchat__send-box__main {
  display: none;
}
.chatbot .webchat__send-box input.webchat__send-box-text-box__input {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.webchat__suggested-actions__stack {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.webchat__suggested-action {
  font-weight: 500;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%);
}

.webchat__suggested-actions__item-box {
  width: 100%;
  // Control spacing between suggested actions
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

@include md {
  .webchat__suggested-actions__item-box {
    width: 490px;
  }
}
