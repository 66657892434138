@import "src/assets/styles/breakpoints";

.main {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 200;
}

.main--agent {
  padding-top: '1rem';
}

.heading {
  z-index: 1000;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 30%);
  flex: 0;
  width: 100%;
  min-height: 64px;
  background-color: #f7f7f7;

  .btn-privacy {
    margin-right: 0.3rem;
    font-weight: bold;
    text-decoration: none;
  }
}

.navbar__main-body {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.navbar__image-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 98px;
  padding-left: 0.3rem;
}

.header__image {
  width: 100%;
}

.vertical__divider {
  flex: 0;
  margin: 0 0.5rem;
  box-shadow: 0px 1px 1px #D3D3D3;
  border-left: 2px solid #D3D3D3;
  padding-top: 0.04em;
}

.header__text {
  flex: 1;
  display: block;
  margin: auto;
  overflow: hidden;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  padding-left: 0.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
  text-align: left;
  color: #022851;
}

.home__body {
  flex: 1;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  min-height: 0;
  background-color: #FFFFFF;
}

@include sm {
  .navbar__image-container {
    max-width: 128px;
  }

  .header__text{
    font-size: 1.25rem;
  }
}
